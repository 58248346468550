


import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({
  name: "Mq4Aq42SWDEGESPdf"
})
export default class Mq4Aq42SWDEGESPdf extends Vue {}
